import Axios from 'axios';
import config from '../constants/config';

export const CommerceApiService = Axios.create({
    baseURL: `${config.ttg_url}${config.commerce_api_path}`,
    headers: {
        'Content-Type': 'application/json',
    },
});
export const getProductById = (productId) => {
    return CommerceApiService.get(`v1/products/${productId}`)
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getStoreProducts = () => {
    return new Promise((resolve, reject) => {
        CommerceApiService.get(`v1/products/no-cache?active=true&limit=100`)
            .then(({ data }) => {
                let bestSellers = [],
                    merchandise = [];
                const allProducts = data
                    ?.filter((product) => product.metadata.store_display_wg).map(product => {
                        if (product.id === config.wg_assessment_stripe_product_id) {
                            return {
                                ...product,
                                name: "The assessment",
                                fullName: product.name,
                                metadata: {
                                    ...product.metadata,
                                    url: `${config.base_url}/about/assessment`,
                                },
                                sort: 1,
                            };
                        }
                        if (product.id === config.TAG_stripe_product_id) {
                            return {
                                ...product,
                                name: "One hour team application guide",
                                fullName: product.name,
                                metadata: {
                                    ...product.metadata,
                                    url: `${config.base_url}/teamapplicationguide`,
                                },
                                sort: 3,
                            };
                        }
                        if (product.id === config.wg_student_assessment_stripe_product_id) {
                            return {
                                ...product,
                                name: "The student assessment",
                                fullName: product.name,
                                metadata: {
                                    ...product.metadata,
                                    url: `${config.base_url}/students`,
                                },
                                sort: 2,
                            };
                        }
                        if (product.id === config.wg_book_stripe_product_id) {
                            return {
                                ...product,
                                name: "The book",
                                fullName: product.name,
                            };
                        }
                        return product;
                    });
                resolve({
                    bestSellers: allProducts.filter((product) => product.metadata.store_category === 'best_seller').sort((a, b) => {
                        if (a.sort !== undefined && b.sort !== undefined) {
                            return a.sort - b.sort;
                        }
                        if (a.sort !== undefined) {
                            return -1;
                        }
                        if (b.sort !== undefined) {
                            return 1;
                        }
                        return 0;
                    }),
                    merchandise: allProducts.filter((product) => product.metadata.store_category === 'merchandise'),
                });
                resolve({
                    bestSellers,
                    merchandise,
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
};
