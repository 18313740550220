import React, { useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { TTGLogin, withSSOAuthProvider } from '@ttg/ui-user-lib';

import Layout from '../../../components/Layout';
import { getQueryParams } from '../../../services/UtilityService';
import AppRIIcon from '../../../components/AppRIIcon';
import { useUser } from '../../../hooks/UserProvider';
import { useNotifications } from "../../../hooks/NotificationProvider";
import config from '../../../constants/config';
import { useSSOParams } from "../../../hooks/useSSOParams";

const TTGLoginWithProvider = withSSOAuthProvider(TTGLogin, useSSOParams);

const Login = () => {
	const location = useLocation();
	const { confirm, redirect, logout, email, type } = getQueryParams(location.search);
	const { user, setUser, loadUser } = useUser();
	const { loadNotifications } = useNotifications();
	const decodedRedirect = redirect ? decodeURIComponent(redirect) : undefined;
	const decodedEmail = email ? decodeURIComponent(email) : undefined;
	const isCheckoutRedirect = decodedRedirect === '/checkout/' || decodedRedirect?.includes('checkout-view');

	const onSuccess = useCallback(async (_, onLoadFinish, redirectPath) => {
		await loadUser();
		await loadNotifications();
		const currentRedirect = decodedRedirect ?? redirectPath ?? '/client/';

		return navigate((currentRedirect).replace('//', '/'), { replace: true });
	}, [setUser, loadNotifications, navigate]);

	useEffect(() => {
		if (user && user.id && !logout) {
			onSuccess(user);
		}
	}, []);


	const renderFooter = () => (
		<div className='flex flex-col items-center my-2'>
			{isCheckoutRedirect ? (
				<span
					className={'flex flex-col items-center text-secondary font-medium my-2 cursor-pointer'}
					onClick={() => window.open('/shopping-cart-view', '_self')}
					id={'wg-login-cart-link'}
				>
					Back to Shopping Cart
				</span>
			) : (
				<div className='md:shadow-md w-full'>
					<div
						className='flex flex-row items-center justify-center cursor-pointer text-white bg-secondary hover:bg-darkenSecondary px-4 py-2'
						onClick={() => navigate('/client/find')}
					>
						<div className={'relative'}>
							<AppRIIcon icon={'survey-line'} className={'text-3xl'} />
							<div className={'absolute'} style={{ bottom: '8px', right: '8px' }}>
								<AppRIIcon icon={'search-2-line'} className={'text-10'} />
							</div>
						</div>
						<p className={'text-lg ml-4 font-medium'}>Find Your Assessment</p>
					</div>
				</div>
			)}
		</div>
	);

	return (
		<Layout title={'Login | The 6 Types Of Working Genius'} style={{ background: '#F5F5F5' }}>
			<div className={'mx-auto mt-12'}>
				<TTGLoginWithProvider
					resource={
						isCheckoutRedirect
							? undefined
							: (type || decodedRedirect)
								? {
									type,
									email: decodedEmail,
									hasTakenWgAssessment: false,
									wgResults: "",
									redirectPath: decodedRedirect,
								}
								: undefined
					}
					captchaEnabled={config.captcha_enabled}
					captchaKey={config.captcha_site_key}
					apiEndpoint={config.ttg_url}
					title={confirm ? 'Login with the new email address' : 'Login'}
					subtitle={'If you already established an account, login below to access your Console.'}
					onFinish={onSuccess}
					onForgotPassword={() => navigate("reminder")}
					footer={renderFooter()}
				/>
			</div>
		</Layout>
	);
};

export default Login;