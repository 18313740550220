import React from 'react';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import StoreHero from '../../components/store/StoreHero';
import Products from '../../components/store/Products';
import WhatElseWeOffer from '../../components/store/WhatElseWeOffer';
import { getStoreProducts } from '../../services/CommerceApiService';
import { StoreStructuredMetaData } from '../../components/store/StoreStructuredMetaData';

const StorePage = () => {
	const [bestSellers, setBestSellers] = React.useState([]);
	const [merchandise, setMerchandise] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		getStoreProducts()
			.then(({ bestSellers, merchandise }) => {
				setBestSellers(bestSellers);
				setMerchandise(merchandise);
			})
			.finally(() => {
				setLoading(false);
				setTimeout(() => {
					const anchor = window.location.hash.slice(1);
					if (anchor) {
						const anchorEl = document.getElementById(anchor);
						if (anchorEl) {
							anchorEl.scrollIntoView();
						}
					}
				}, [300]);
			});
	}, []);

	return (
		<WorkshopLayout title={'Store  | The 6 Types of Working Genius'}>
			<StoreHero />
			{loading ? (
				<div id={'events-loading-spinner'} className='relative'>
					<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center my-8'>
						<div className='spinner'>
							<div className='double-bounce1' />
							<div className='double-bounce2' />
						</div>
					</div>
				</div>
			) : (
				<>
					<StoreStructuredMetaData products={bestSellers.concat(merchandise)} />
					<div id={'best-seller'}>
						<Products title='Best-Sellers' products={bestSellers} />
					</div>
					<div id={'merch'}>
						<Products title='Merchandise' products={merchandise} />
					</div>
					<div id={'other'}>
						<WhatElseWeOffer />
					</div>
				</>
			)}
		</WorkshopLayout>
	);
};

export default StorePage;
