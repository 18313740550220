import React from 'react';
import AssessmentAboutBuy from '../../../components/about/assessment/AssessmentAboutBuy';
import AssessmentAboutBuyNow from '../../../components/about/assessment/AssessmentAboutBuyNow';
import AssessmentAboutResults from '../../../components/about/assessment/AssessmentAboutResults';
import AssessmentStudent from '../../../components/about/assessment/AssessmentStudent';
import AssessmentAboutFAQ from '../../../components/about/assessment/AssessmentAboutFAQ';
import { CommerceApiService } from '../../../services/CommerceApiService';
import config from '../../../constants/config';
import CustomerService from '../../../services/CustomerService';
import AssessmentAboutDifference from '../../../components/about/assessment/AssessmentAboutDifference';
import { ProductStructuredMetaData } from '../../../components/store/ProductStructuredMetaData';

const AssessmentAbout = () => {
	const [item, setItem] = React.useState(null);
	React.useEffect(() => {
		CommerceApiService.get(`v1/products/${config.wg_assessment_stripe_product_id}`)
			.then(({ data }) => {
				setItem(data);
				CustomerService.triggerGoogleTagManagerEvent({
					value: (data?.defaultPrice?.unit_amount || 0) / 100,
					products: [data],
				});
			})
	}, []);

	return (
		<>
			{item && <ProductStructuredMetaData product={item} />}
			<AssessmentAboutBuy />
			<AssessmentAboutBuyNow />
			<AssessmentAboutDifference />
			<AssessmentAboutResults />
			<AssessmentStudent />
			<AssessmentAboutFAQ />
		</>
	);
};

export default AssessmentAbout;
