import React, { useState, useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from '../../../components/Layout';
import Hero from '../../../components/certification/resources/Hero';
import Resources from '../../../components/certification/resources/Resources';
import AppFAQ from '../../../components/AppFAQ';
import DuringTraining from '../../../components/certification/register/DuringTraining';
import PatQuote from '../../../components/certification/register/PatQuote';
import Events from '../../../components/certification/register/Events';
import AppActiveCampaignForm from '../../../components/AppActiveCampaignForm';
import { getCertificationDates, getCertificationResources } from '../../../services/CertificationService';
import CustomerService, { addToCart } from '../../../services/CustomerService';
import WorkshopLayout from '../../../components/workshop/WorkshopLayout';
import CertificationHero from '../../../components/certification/register/CertificationHero';
import CertificationReviews from '../../../components/certification/register/CertificationReviews';
import AboutCertification from '../../../components/certification/register/AboutCertification';
import DalePartnership from '../../../components/certification/register/DalePartnership';
import config from '../../../constants/config';
import { useUser } from "../../../hooks/UserProvider";
import { CommerceApiService } from '../../../services/CommerceApiService';
import NotificationService from '../../../services/NotificationService';
import { certificationBadgeIcon } from '../../../images';
import { CertificationStructuredMetaData } from '../../../components/store/CertificationStructuredMetaData';

const ALUMNI_INTENSIVE_TILE = {
	"post_title": "Alumni Intensives",
	"post_name": "Alumni Intensives",
	"post_type": "wg-custom-post",
	"page_template": "",
	"post_category": [],
	"guid": "AlumniIntensive",
	"image_url": "https://files.tablegroup.com/wp-content/uploads/2024/08/21101343/pGKiW7iQ.webp",
	"use_default_img": true,
	"file_url": "/client/alumni-intensives/",
	"post_excerpt": 'View and register for available Alumni Intensives.'
};
const FAQ = [
	{
		title: 'Discount Code Use',
		answer: "As a certified Working Genius facilitator, this code is for your use only. We aren't exclusive often, but this is one of those times. You've earned it!",
	},
	{
		title: 'Will the Table Group be offering more Certification Resources?',
		answer: 'As you know, we are constantly refining and exploring new ways to apply the Working Genius model. The Certification Portal will be your go-to spot for all updated materials and new resources. As we add more items, we will email you regarding updates or webinar opportunities.',
	},
	{
		title: "Can I move someone's assessment results into my portal?",
		answer: 'If you need an individual’s assessment results in your portal to create a Team Map, please ask them to click “Share Assessment Report” from their Working Genius console. In the event they don’t have a console, they can create one by clicking “Login” and “Find my Assessment.”',
	},
	{
		title: 'How can I connect with other Working Genius Certified participants on LinkedIn?',
		answer: "We've created a <a style='text-decoration: underline;' href='https://www.linkedin.com/groups/13965517/' target='_blank'>LinkedIn group</a> so that anyone who is Working Genius Certified can share stories or discuss their experiences with the tool.",
	},
	{
		title: 'How much should I charge for a Working Genius Session?',
		answer: 'Because of the variables associated with Working Genius Sessions, we are not able to advise on rates. The size of the group, type of organization, geographics of your location in the world, demographics of the people you are working with, and even your level of experience and expertise will all impact what you charge.',
	},
	{
		title: 'Can I buy an assessment for someone?',
		answer: 'Yes, you can purchase one or more licenses, and then from the home page within the Administrator\'s Console, you can select the blue icon that reads, "Send Assessment to Others."',
	},
	{
		title: 'How do I add additional license(s) to an open group?',
		answer: '<p>1. Purchase the additional assessment license(s) if you do not have any remaining. </p> <p>2. Choose the group you wish to modify on the assessments page in the Console.</p> <p>3. After selecting the appropriate team, select the add licenses to this group in the right corner of the page.</p> <p>4. Send an email containing the unique link to the new group/team members.</p>',
	},
	{
		title: 'Can I generate a team map in my portal?',
		answer: "<p> Short answer - yes. Long answer - you have two options. </p><p>1. If you don't have people's assessment results in your portal, you can manually enter names onto a blank team map that is under your <a href=\"/client/tools/\">Resources</a> Section.</p> <p>2. If you purchased and sent assessments to a group or individuals, then you can automatically generate a team map listing their geniuses and frustrations. The only caveat is that your team needs to be less than 16 people, otherwise it's too many to fit on the document. </p>",
	},
];

const sections = [
	{
		key: 'certification_session_materials',
		title: 'Certification Session Materials',
		info: 'We have included the Slide Deck and Companion Guide from the Working Genius Certification Session for your reference.',
	},
	{
		key: 'facilitator_training_tool_kit',
		title: 'Facilitator Training Tool Kit',
		info: 'As you apply the Working Genius model for individual coaching, client meetings and team development, you can use any of these templates to create and deliver training session content.',
	},
	{
		key: 'videos',
		title: 'Videos',
		info: 'These videos were created specifically for Certification. You viewed them for pre-work, but we also wanted them to be available for you to use with a client or during a training. Please select the post to view or download each video.',
	},
	{
		key: 'marketing_resources',
		title: 'Marketing Resources',
		info: 'We hope that Certification training and all of the tools above help you feel equipped to work with individuals and teams. Here are just a couple of resources and guides to reference as you share about your Certification and the Working Genius assessment.',
	},
	{
		key: 'recordings',
		title: 'Additional Resources',
		info: 'Exclusive content, PDF’s, and other resources just for our Certified Facilitator Network.',
	},
	{
		key: 'alumni_intensives',
		title: 'Alumni Intensives',
		info: 'Registration and resources for virtual intensives, exclusive for Certified Facilitators.',
	},
];

const Certification = () => {
	const [notify, setNotify] = useState(false);
	const [loading, setLoading] = useState(true);
	const [availableEvents, setAvailableEvents] = useState([]);
	const [soldOutEvents, setSoldOutEvents] = useState([]);
	const [resources, setResources] = useState([]);
	const [item, setItem] = useState(null);
	const [showToaster, setShowToaster] = useState(false);
	const registerEl = useRef();
	const { hash } = useLocation();
	const { user, hasPermission, switchToOriginalUser } = useUser();

	const getInitialData = () => {
		if (hasPermission('WG_CERTIFICATION.GET') && user?.hasPurchasedCertification) {
			if (user?.hasCompletedPreWork) {
				getCertificationResources()
					.then((res) => {
						const purchased_alumni_ids = user?.wg_alumni_intensives
							?.filter(item => item?.variation_id)
							?.map(item => item.variation_id) || [];

						const alumni_intensive_resources = res?.alumni_intensives
							?.filter(resource =>
								resource?.alumni_intensives_variation_ids?.some(id => purchased_alumni_ids.includes(id)))
							|| [];

						res.alumni_intensives = [
							ALUMNI_INTENSIVE_TILE,
							...alumni_intensive_resources?.map( item => ({
								...item,
								image_url: ALUMNI_INTENSIVE_TILE.image_url,
								guid: 'AlumniIntensiveResource',
								use_default_img: ALUMNI_INTENSIVE_TILE.use_default_img
							}))
						];

						if(!config.alumni_intensive_feature){
							res.alumni_intensives = null;
						}

						setResources(res);
					})
					.finally(() => {
						setLoading(false);

						setTimeout(() => {
							const anchor = window?.location?.hash?.slice(1);
							const anchorEl = document?.getElementById(`section-${anchor}`);
							if (anchorEl) {
								anchorEl?.scrollIntoView({
									behavior: 'smooth',
									block: 'center',
									inline: 'center'
								});
							}
						}, [300]);
					});
			}
		} else {
			getCertificationDates()
				.then(({ available, soldOut }) => {
					setAvailableEvents(available);
					setSoldOutEvents(soldOut);
					checkForHash();
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const getStripeProduct = () => {
		CommerceApiService.get(`v1/products/${config.certification_stripe_product_id}`)
			.then(({ data }) => {
				setItem(data);
			})
	}

	useEffect(() => {
		getInitialData();
		getStripeProduct();
	}, []);

	const checkForHash = () => {
		if (hash === '#register') {
			redirectTo();
		}
	};

	const handleSwitchBack = () => Promise.resolve(switchToOriginalUser());

	const onRegisterAsAdmin = () => {
		!showToaster && NotificationService.info(
			'Certification can only be purchased in your personal account.',
			null,
			{ duration: Infinity },
			[{ text: 'Switch to my account', action: () => handleSwitchBack() }],
			certificationBadgeIcon,
			() => setShowToaster(false)
		);
		setShowToaster(true);
	}

	const isAdmin = user?.original_customer;
	const handleGetCertifiedClick = () => {
		CustomerService.triggerGoogleTagManagerEvent({
			value: availableEvents?.at(0)?.defaultPrice,
			products: availableEvents?.map(event => {
				return {
					id: `${event?.variation_id}_${event?.certification_product_id}`,
					name: 'Working Genius Certification',
					value: event?.defaultPrice || 0,
					quantity: 1,
					type: 'Working Genius Certification',
				}
			})
		});
		isAdmin && onRegisterAsAdmin();
		redirectTo();
	}

	if (hasPermission('WG_CERTIFICATION.GET') && user?.hasPurchasedCertification) {
		if (!user?.hasCompletedPreWork) {
			navigate('/client/certification/prework/');
			return null;
		}

		return (
			<Layout containerClass={'cert-resources bg-gray-100'}>
				<Hero />
				<div className='mx-4 md:mx-24'>
					<Resources loading={loading} sections={sections} resources={resources} />
				</div>
				<AppFAQ items={FAQ} />
			</Layout>
		);
	}

	const redirectTo = () => {
		registerEl.current.scrollIntoView();
	};

	const onRegisterClick = (event) =>
		addToCart('certification_product_id', event.certification_product_id, event.variation_id);

	const onNotify = () => {
		setNotify(true);
	};

	return (
		<WorkshopLayout
			title={'Become A Certified Coach | The 6 Types Of Working Genius'}
			description={
				'Become a certified Working Genius coach and expert allowing you to teach the model and become a facilitator for other teams and businesses.'
			}
		>
			{item && <CertificationStructuredMetaData product={item} events={availableEvents}/>}
			<CertificationHero redirectTo={handleGetCertifiedClick} />
			<div className={'certification-bar'}>
				<p className='text-primary text-3xl'>Benefits of Certification</p>
			</div>
			<DuringTraining />
			<PatQuote />
			<AboutCertification />
			<div style={{ background: '#dedfe1', padding: '3rem 0' }} ref={registerEl}>
				<Events
					title={'Available Dates'}
					events={[...availableEvents, { notify: true }, { spanish: true }, { german: true }]}
					onRegister={onRegisterClick}
					onNotify={onNotify}
					loading={loading}
					onRegisterAsAdmin={onRegisterAsAdmin}
					isAdmin={isAdmin}
				/>
				<Events
					title={'Sold Out Dates'}
					events={soldOutEvents}
					onRegister={onRegisterClick}
					loading={loading}
				/>
				<div className={'container mx-auto flex p-8'}>
					<p className={'text-primary text-base md:text-xl text-center font-thin'}>
						Please Note: We will honor all refunds up to 14 days before your scheduled Certification
						Session, should you need to cancel. If you wish to reschedule to a new date, please reach out to
						us via email at&nbsp;
						<a className={'font-medium'} href='mailto:certification@workinggenius.com'>
							certification@workinggenius.com
						</a>{' '}
						or call us at{' '}
						<a href='tel:+19252999700' className={'font-medium'}>
							(925) 299-9700
						</a>
						. We are able to accommodate re-schedules once and up until a maximum of 7 days in advance of an
						open session to allow for certification pre-work requirements.
					</p>
				</div>
				{!!notify && (
					<AppActiveCampaignForm
						src={config.ac_certification_subscription_link}
						onClose={() => setNotify(false)}
					/>
				)}
			</div>
			<DalePartnership />
			<CertificationReviews />
		</WorkshopLayout>
	);
};

export default Certification;
