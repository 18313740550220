import React, { useEffect, useState } from 'react';

import Book6Types from '../../components/book/Book6Types';
import BookHero from '../../components/book/BookHero';
import BookPreorder from '../../components/book/BookPreorder';
import BookVideo from '../../components/book/BookVideo';
import BookWhatPeopleSay from '../../components/book/BookWhatPeopleSay';
import BookJoinLaunchTeam from '../../components/book/BookJoinLaunchTeam';
import LaunchEventTypes from '../../components/launchevent/LaunchEventTypes';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import EmailCollector from '../../components/homepage/EmailCollector';
import BookService from '../../services/BookService';
import { CommerceApiService } from '../../services/CommerceApiService';
import config from '../../constants/config';
import { ProductStructuredMetaData } from '../../components/store/ProductStructuredMetaData';

const Book = () => {
	const [item, setItem] = useState(null);
	useEffect(() => {
		CommerceApiService.get(`v1/products/${config.wg_book_stripe_product_id}`)
			.then(({ data }) => {
				setItem(data);
			})
	}, []);

	return (
		<WorkshopLayout
			title={'Buy The Book | The 6 Types Of Working Genius'}
			description={
				'Buy the 6 Types of Working Genius book to discover your geniuses and revolutionize your work!'
			}
		>
			{item && <ProductStructuredMetaData product={item} />}
			<BookHero />
			<BookWhatPeopleSay />
			<BookPreorder />
			<Book6Types />
			<EmailCollector
				title={'Get a free sample of the book'}
				successMessage={'Thanks for requesting a sample of the book. Look for it in your inbox!'}
				onSubmit={BookService.sendSampleBook}
			/>
			<BookVideo />
			<LaunchEventTypes longTitle={true} />
			<BookJoinLaunchTeam />
		</WorkshopLayout>
	);
};

export default Book;
