import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import StudentsHero from '../../components/students/StudentsHero';
import WhyWorkingGenius from '../../components/students/WhyWorkingGenius';
import StudentsChecklist from '../../components/students/StudentsChecklist';
import ParentsChecklist from '../../components/students/ParentsChecklist';
import Event6Types from '../../components/event/Event6Types';
import StudentsReviews from '../../components/students/StudentsReviews';
import FreeDiscussionGuide from '../../components/students/FreeDiscussionGuide';
import Podcast from '../../components/students/Podcast';
import BuyNow from '../../components/students/BuyNow';
import AppButton from '../../components/AppButton';
import { ProductStructuredMetaData } from '../../components/store/ProductStructuredMetaData';
import { CommerceApiService } from '../../services/CommerceApiService';
import config from '../../constants/config';

const StudentsPage = () => {
	const [item, setItem] = useState(null);
	useEffect(() => {
		CommerceApiService.get(`v1/products/${config.wg_student_assessment_stripe_product_id}`)
			.then(({ data }) => {
				setItem(data);
			})
	}, []);

	return (
		<WorkshopLayout
			title={'Assessment for Students | The 6 Types of Working Genius'}
			description={
				'The Working Genius assessment that has helped nearly one million people discover their gifts and thrive at work is now designed for students!'
			}
			studentLogo={true}
		>
			{item && <ProductStructuredMetaData product={item} />}
			<StudentsHero />
			<WhyWorkingGenius />
			<StudentsChecklist />
			<ParentsChecklist />
			<BuyNow />
			<StudentsReviews />
			<Event6Types leftPositioned={true} longTitle={true} />
			<FreeDiscussionGuide />
			<div className='bg-student py-12 text-center'>
				<h4 className='text-xl lg:text-2xl font-normal text-primary my-4'>Frequently Asked Questions</h4>
				<AppButton
					className={'btn btn-white'}
					text={'Get answers'}
					onClick={() => navigate('/faq#faq-student-assessment')}
				/>
			</div>
			<Podcast />
		</WorkshopLayout>
	);
};

export default StudentsPage;
