import React, { useState } from 'react';

import WorkshopLayout from '../../../components/workshop/WorkshopLayout';
import HeroSection from '../../../components/toolkit/product/HeroSection';
import StepGuide from '../../../components/toolkit/product/StepGuide';
import Buy from '../../../components/toolkit/product/Buy';
import Topics from '../../../components/toolkit/product/Topics';
import LastBuy from '../../../components/toolkit/product/LastBuy';
import Quote from '../../../components/toolkit/product/Quote';
import { CommerceApiService } from '../../../services/CommerceApiService';
import config from '../../../constants/config';
import CustomerService from '../../../services/CustomerService';
import { ProductStructuredMetaData } from '../../../components/store/ProductStructuredMetaData';

const Product = () => {
	const [item, setItem] = useState(null);
	React.useEffect(() => {
		CommerceApiService.get(`v1/products/${config.TAG_stripe_product_id}`)
			.then(({ data }) => {
				setItem(data);
				CustomerService.triggerGoogleTagManagerEvent({
					value: (data?.defaultPrice?.unit_amount || 0) / 100,
					products: [data],
				});
			})
	}, []);

	return (
		<WorkshopLayout
			title={'Team Application Guide | The 6 Types of Working Genius'}
			description={'Your digital toolkit for team adoption and impact of The 6 Types of Working Genius'}
		>
			<div id={'wg-team-app-guide'}>
				{item && <ProductStructuredMetaData product={item} />}
				<HeroSection />
				<StepGuide />
				<Buy />
				<Topics />
				<LastBuy />
				<Quote />
			</div>
		</WorkshopLayout>
	);
};

export default Product;
